export const SET_AUTH = "SET_AUTH";
export const REMOVE_AUTH = "REMOVE_AUTH";

export const SET_LANGUAGE = "SET_LANGUAGE";
export const FETCH_LANGUAGE = "FETCH_LANGUAGE";

export const FETCH_QUESTIONS = "FETCH_QUESTIONS";
export const SET_QUESTIONS = "SET_QUESTIONS";

export const FETCH_DASHBOARD = "FETCH_DASHBOARD";
export const SET_DASHBOARD = "SET_DASHBOARD";

export const FETCH_FILTER = "FETCH_FILTER";
export const SET_FILTER_PAGE = "SET_FILTER_PAGE";
export const SET_FILTER_SORT = "SET_FILTER_SORT";
export const SET_FILTER_VALUE = "SET_FILTER_VALUE";
export const SET_FILTER_ALL = "SET_FILTER_ALL";

