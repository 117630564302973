import { SET_AUTH, REMOVE_AUTH } from "../../actions/action.constants";

export const AuthReducer = (state = [], { type, payload }) => {
  switch (type) {
    case SET_AUTH:      
      return { ...state, ...payload };
    case REMOVE_AUTH:      
      return { ...state, ...payload };
    default:
      return state;
  }
};
